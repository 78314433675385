/**
 * Progress bar styles.
 */
.progress-wrapper {
  margin-bottom: $form-group-margin-bottom;

  &:last-child .progress {
    margin-bottom: 5px;
  }

  .message {
    font-weight: 700;
    margin-bottom: 5px;
  }

  .percentage,
  .progress-label {
    font-size: $font-size-small;
  }

  .progress-bar {
    min-width: 2em;
  }

}
