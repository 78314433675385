// @todo these should be their own CSS files and only included when
// specific Drupal libraries are used.
@import "component/alert";
@import "component/ajax";
@import "component/field";
@import "component/file";
@import "component/filter";
@import "component/form";
@import "component/icon";
@import "component/navbar";
@import "component/node";
@import "component/panel";
@import "component/progress-bar";
@import "component/table-drag";
@import "component/tabs";
@import "component/toolbar";

// jQuery UI style overrides.
@import "jquery-ui/autocomplete";

ol, ul {
  padding-left: 1.5em;
  .popover &:last-child {
    margin-bottom: 0;
  }
}

.btn-rnd {
  display: inline-block;
  border: 2px solid #ffffff;
  border-radius: 0;
  color: #ffffff;
  padding: 10px 40px;
  margin: 40px 0;
}

.btn-rnd:hover {
  color: #333;
  background-color: #ffffff;
}

.btn-rnd.btn-blue {
  display: inline-block;
  border: 2px solid #333;
  background-color: #ffffff;
  border-radius: 0;
  color: #333;
  padding: 10px 40px;
  margin: 40px 0;
  font-family: "effra",sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
}

.btn-rnd.btn-blue:hover {
  color: #00afec;
  border: 2px solid #00afec;
  text-decoration: none;
}

body {
  margin:0;
  background-color: #fff;
  font-family: "effra",sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 135%;
}


a, a:hover, h1, h2, h3 {
  color: #00afec;
}

h2, h3 {
  font-size: 32px;
  line-height: 135%;
  font-weight: 300;
  margin-bottom: 15px;
}

.center { text-align: center; }
.padding-lrg { padding-top: 60px; padding-bottom: 60px; }
.padding-lrg-top { padding-top: 60px; }
.padding-lrg-bottom { padding-bottom: 60px; }

#header {
  padding: 16px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  .logo-white {
    opacity: 0;
  }
  .logo-blue {
    opacity: 1;
    width: 100%;
  }
  .header-bg {
    background-color: #ffffff;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition-duration: 1s;
  }
  .menu.navbar-nav {
    li {
      margin: 0 10px;
      a {
        color: #00afec;
        transition-duration: 1s;
        font-family: "effra",sans-serif;
        font-weight: 300;
        font-size: 18px;
        padding: 10px 0 5px;
      }
      a:hover, a:focus {
        background-color: transparent;
        border-bottom: 1px solid #00afec;
      }
    }
    li.active {
      a {
        border-bottom: 1px solid #00afec;
      }
      ul {
        li {
          a {
            border-bottom: none;
          }
          a:hover, a:focus {
            background-color: transparent;
            border-bottom: 1px solid #00afec;
          }
        }
        li.active {
          a {
            border-bottom: 1px solid #00afec;
            background-color: transparent;
          }
        }
      }
    }
  }
  .dropdown-menu {
    position: fixed;
    top: 106px;
    right:0;
    background-color: #00afec;
    border: 0;
    z-index: 2;    
    padding:0;
    height: 50px;
    border-radius: 0;
    text-align: center;
    transition-duration: 1s;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: none !important;
    li {
      display: inline-block;
      a {
        display: inline-block;
        padding: 12px 0px 5px;
        margin: 0 20px;
        color: #00afec !important;
        margin: 0;
        font-family: "effra",sans-serif;
        font-weight: 300;
        font-size: 18px;
        background-color: transparent;
      }
      a:hover, a:focus {
        background-color: transparent;
        border-bottom: 1px solid #00afec;
      }
    }
    li.active {
      a {
        border-bottom: 1px solid #00afec;
        background-color: transparent;
      }
    }
  }
  .icon {
    color: #00afec;
  }
  .form-group { 
    margin:0;
    .input-group {
      margin-top: 4px;
      input {
        display: none;
        border-radius: 0px;
        background-color: transparent;
        color: #00afec;
      }
      input.search-this {
        display: block;
      }
      button {
        background-color: transparent;
        border: none;
        float: right;
      }
    }
  }
  .logo-container {
    position: relative;
    overflow: hidden;
    max-width: 230px;
    img {
      width: 100%;
      transition-duration: 1s;
    }
  }
}

#header.scrolled {
  padding: 16px 0;
  .header-bg {
    opacity: 1;
  }
  .logo-white {
    opacity: 0;
  }
  .logo-blue {
    opacity: 1;
  }
  .navbar-nav {
    li {
      a {
        color: #00afec;
      }
      a:hover, a:focus {
        border-bottom: 1px solid #00afec;        
      }
    }
    li.active {
      a {
        border-bottom: 1px solid #00afec;
      }
    }
  }
  .dropdown-menu {
    top: 74px;
    transition-duration: 1s;
  }
  .icon {
    color: #00afec;
  }
    input {
      border: 1px solid #00afec !important;
      color: #00afec !important;
  }
}

.field--name-field-intro-title {
  color: #00afec;
  font-size: 32px;
  line-height: 135%;
  margin-bottom: 15px;
}

.map-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.search-block-form {
  width: 200px;
  float:right;
}
#video-wrapper {
  position: relative;
  overflow:hidden;
  height: 100vh;
  min-height: auto;
  padding: 170px 0 79px;
  text-align: center;
  background-size: cover;
  background-position: center center;
}

#video {
  height: 100%;
  z-index: 2;
  position: relative;
  .content-overlay {
      position: relative;
      overflow: hidden;
      padding: 0 20px;
      height: 612px;
      z-index: 1;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // .field--name-field-banner-text {
      //   position: absolute;
      //   top: 50%;
      //   width: 100%;
      //   text-align: center;
      // }
      .field--name-field-banner-title {
        font-size: 30px;
        line-height: 135%;
        margin-bottom: 10px;
      }
      .field--name-field-banner-text {
        font-size: 14px;
        line-height: 135%;
      }      
      a {
        text-decoration: none;
      }
      .btn-down {
        position: absolute;
        bottom: 0;
      }
  }
}

#video-wrapper.full-height {
  height: 101vh;
  padding: 0;
  .content-overlay {
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
    z-index: 4;
  }
}

/* The only rule that matters */
#video-background {
/*  making the video fullscreen  */
  position: fixed;
  right: 0; 
  top: 0;
  min-width: 100%; 
  min-height: 100%;
  width: auto; 
  height: auto;
  z-index: -100;
  opacity: 1;
}

.view-banner-section {
  .banner-overlay {
      position: absolute;
      overflow: hidden;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
      z-index: 1;
      background-color: #000000;
      opacity: 0.35;
  }
  .content-overlay {
      position: absolute;
      overflow: hidden;
      top: 0;
      width: 100%;
      left: 0;
      z-index: 2;
      padding: 60px 20px 0;
      height: 600px;
      z-index: 1;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .banner-title, .banner-text {
        display: block;
      }
      .banner-title {
        font-size: 32px;
        line-height: 135%;
        margin-bottom: 10px;
      }
      .banner-text {
        font-size: 14px;
        line-height: 135%;
      }
      a {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -15px;
        color: #ffffff;
        font-size: 42px;
        line-height: 135%;
      }
  }
}

.banner-section {
  position: relative;
  overflow:hidden;
  height: 600px;
  min-height: 600px;
  text-align: center;
  z-index: 3;
  #video-background {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }
  .banner {
    height: 100%;
    height: 600px;
    background-size: cover;
    background-position: center center;
    .video-content-url {
      display: none;
    }
  }
}

.page-title {
  //display:none;
}

.bg-white { background-color: #ffffff; }


// Page header.
.page-header {
  margin-top: 0;
}

// Project

#block-views-block-projects-block-1, #block-views-block-projects-related-projects {
  padding: 50px 15px;
  .view-content {
    margin-left: -15px;
    margin-right: -15px;
    .project {
      display: block;
      .project-img {
        background-size: cover;
        background-position: top;
        height: 240px;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        -webkit-transition-duration: 1s;
        -moz-transition-duration: 1s;
        -o-transition-duration: 1s;
        transition-duration: 1s;
      }
    }
    .project:hover {
      .project-img {
        -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
        filter: grayscale(0);    
      }  
    }
    .inner {
      padding-bottom: 40px;
      a.project {
        color: #333;
      }
      h3 {
        margin-bottom: 5px;
        font-size: 22px;
        color: #333;
      }
    }
  }
}

.view-project-filter {
  position: relative;
  .view-content {
    position: absolute;
    top:-90px;
    left:50%;
    margin-left: - 125px;
    z-index: 5;
    display: none;
    float: left;
    .project-type {
      display: block;
      clear: both;
      margin: -1px 0 0 0;
      background-color: #ffffff;
      opacity: 0.90;
    }
  }
  .view-content.open {
    display: block;
  }
}

.project-type-container { display: inline-block; }

.project-type {
  //display: inline-block;
  padding: 10px 20px;
  //border-radius: 1px;
  border: 1px solid #bcbcbc;
  color: #000;
  //margin-left: 1%;
  //margin-right: 1%;
  //margin-bottom: 20px;
  cursor: pointer;
}


.project-type:hover, .project-type.active {
  border: 1px solid #00afec;
  color: #fff;
  background-color: #00afec !important;
}



#block-views-block-people-people {
  padding: 50px 15px;
  .view-content {
    margin-left: -15px;
    margin-right: -15px;
    .people {
      margin-bottom: 40px;
      .people-img {
        background-size: cover;
        background-position: top;
        height: 270px;
        margin-bottom: 20px;
        display: block;
      }
      .people-info {
        display: block;
        font-size: 16px;
      }
      .people-name {
        font-size: 22px;
      }
      .people-job {
        font-size: 20px;
      }

    }
    .inner {
      padding-bottom: 40px;
    }
  }
}

#block-views-block-services-block-1, #block-views-block-services-related-services {
  padding: 0 15px;
  text-align: center;
  .view-content {
    margin-left: -15px;
    margin-right: -15px;
    .service {
      display: block;
      text-align: center;
      cursor: pointer;
      img, svg {
        max-width: 100px;
        margin: 0 auto;
      }
      svg path {
        fill: #333333;
      }
      svg:hover path {
          fill: #00afec;
      }
      .service-info {
        display: block;
        font-size: 16px;
      }
      .service_description {
        font-size: 20px;
        line-height: 135%;
        text-align: center;
        color: #333;
        display: block;
      }
      .read-more {
        display: inline-block;
        border: 2px solid #333;
        border-radius: 20px;
        color: #333;
        padding: 5px 30px;
        margin: 20px 0;        
      }
    }
    .service:hover {
      h3 {
        color: #00afec;
      }
      .read-more {
        border: 2px solid #00afec;
        color: #00afec;   
        text-decoration: none; 
      }
      svg path {
        fill: #00afec;
      }
    }
    .inner {
      padding-bottom: 40px;
      display: block;
    }
  }
}

.service-other {
  svg {
    max-width: 100px !important;
  }
  h3 {
    margin-top: 0;
  }
}

.field--name-field-service-people {
  margin: 80px 0 0 0;
  .field--item {
    display: inline-block;
    float: left;
    .node--type-people {
      display: inline-block;
      width: 100%;
      margin: 0 15px;
      line-height: 135%;
      text-align: left;
      header {
        display: none;
      }

        .field--name-field-image {
          width: 30%;
          max-width: 200px;
          margin-right: 5%;
          img {
            background-size: cover;
            background-position: top;
            margin-bottom: 20px;
            display: block;
          }
        }
        .field--name-field-telephone, .field--name-field-email, .field--name-field-vcard {
          display: block;
          font-size: 16px;
          margin: 0;
           width: 50%;
        }
        .field--name-field-telephone:before, .field--name-field-email:before {
          display: none;
        }
        .field--name-field-name {
          font-size: 22px;
          width: 50%;
        }
        .field--name-field-job {
          font-size: 20px;
        }    
        .field--name-field-vcard {
          .file-icon { display: none; }
          .file-size { display: none; }
          span, a {
            border: none;
            color: #00afec;
            font-weight: 100;
            font-size: 16px;
          }
        }
    }
  }
}

.break-image {
  height: 450px;
  width: 100%;
  background-position: center center;
  background-size: cover;
}

.field--name-field-content {
  h3 {
    margin: 20px 0 5px;
  }
  h3:first-of-type {
    margin-top: 0;
  }
}

.project {
  .field--name-field-content {
    text-align: left;
  } 
}


/* Contact */

.field--name-field-email, .field--name-field-telephone {
  margin: 20px 0;
  display: block;
}

.field--name-field-email:before {
  content: "\f0e0";
  margin-right: 20px;
  font: normal normal normal 14px/1 FontAwesome;
}

.field--name-field-telephone:before {
  content: "\f095";
  margin-right: 20px;
  font: normal normal normal 14px/1 FontAwesome;
}


/* Search results */

.search-help-link {
  display: none;
}

.search-results-container {
  margin: 100px 0;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 30px 0;
  background-color: #e5e5e5;
  z-index: 4;
  font-size: 16px;
  .nav {
    float: right;
    li {
      a {
        padding: 0;
        font-size: 16px;
        color: #555555;
      }
      a:hover {
        background-color: transparent;
      }
    }
  }
}

.homepage-footer {
  background-color: transparent;
  color: #ffffff;
  border-top: 1px solid #ffffff;
  a {
    color: #ffffff;
  }
}

.slide_in_content {
  footer {
    position: fixed;
    z-index: 1;
  }
}

// BG IMG Section

.bg-image-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .bg-image {
    position: absolute;
    height: 100%;
    width: 100%; 
    z-index: 1;
    background-color: #fff;
  }
  .content-transition {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .overlay-bg {
      background-color: #000;
      opacity: 0.6;    
      position: absolute;
      height: 100vh;
      width: 100%;
      z-index:2;
    }
    .overlay-content {
      z-index: 3;
      padding: 0 10%;
      text-align: center;
      p, h2 {
        color: #ffffff;
      }
    }
  }
  .lrg-trans {
    overflow: scroll;
    display: block;
    .overlay-bg {
      display: none;
    }
    .overlay-content {
      padding: 10%;
      position: relative;
      .content {
        position: relative;
        overflow: hidden;
        z-index: 3;
      }
      .overlay-bg-lrg {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.6;    
        width: 100%;
        z-index:2;
      }
    }
  }
}

// Paragraphs.
p:last-child,
.form-group:last-child,
.panel:last-child {
  margin-bottom: 0;
}

// Help region.
.region-help {
  > .glyphicon {
    font-size: $font-size-large;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}


.help-block, .control-group .help-inline {
  color: $gray-light;
  font-size: 12px;
  margin: 5px 0 10px;
  padding: 0;
  &:first-child {
    margin-top: 0;
  }
}

.caption {
    position: relative;
    text-align: center;
    color: #fff;
    height: 100vh;
}
.caption-content {
    z-index: 2;
    position: relative;
    display: block;
    padding: 0 10%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.caption-bg {
    background-color: #000000;
    padding: 10%;
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.4;
    z-index: 1;
}
 
#slides-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    opacity: 0.2;
    z-index: 2;
}
 
#slides {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
.slide {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
#slide-1 {
    background: url('../img/bcg_slide-1.jpg') no-repeat center center;
    background-size: cover;
}

#helper {
    position:absolute;
    left:0;
    top: 0;
    height:600%;
    width: 0;
}
.marker {
    height: 25%;
    width: 100%;
}

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}




.new-header--open .veggie-burger {
    z-index: 1071;
}


@media (max-width: 991px){
.new-header--open .veggie-burger:before {
    height: 6.75rem;
    width: 3.375rem;
    top: -1.6875rem;
    right: 1.6875rem;
    -webkit-border-radius: 6.75rem 0 0 6.75rem;
    border-radius: 6.75rem 0 0 6.75rem;
}

#header {
  padding: 16px 0 !important;
}

.new-header--open .veggie-burger:before {
    -webkit-border-radius: 5.25rem 0 0 5.25rem;
    border-radius: 5.25rem 0 0 5.25rem;
    content: '';
    height: 5.25rem;
    position: absolute;
    right: 1.3125rem;
    top: -1.3125rem;
    width: 2.625rem;
}


.veggie-burger__icon, .veggie-burger__icon:before, .veggie-burger__icon:after {
    background-color: currentColor;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 30px;
}

.veggie-burger__icon:before {
    top: -8px;
}

.veggie-burger__icon:after {
    bottom: -8px;
}


.veggie-burger__icon, .veggie-burger__icon:before, .veggie-burger__icon:after {
    background-color: currentColor;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 30px;
}

.new-header--open .veggie-burger__icon:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #ffffff;
}

.new-header--open .veggie-burger__icon:after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    color: #ffffff;
}

.menu__inner {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.gs-container {
    position: relative;
    margin: 0 auto;
}


.veggie-burger {
    color: #00afec;
    cursor: pointer;
    height: 50px;
    min-width: 50px;
    position: fixed;
    top: 15px;
    border: 0;
    border-radius: 50%;
    outline: none;
    right: 30px;
    //background-color: #00afec;
    padding: 18px 0 0 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1000;
}

.new-header--open .veggie-burger__icon {
    background-color: transparent;
}

.veggie-burger__icon {
  left: 10px;
  top: 23px;
}

.veggie-burger__label {
    display: none;
}

.menu {
    background-color: #00afec;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    font-family: "Guardian Text Sans Web","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
    font-size: 30px;
    left: 0;
    line-height: 1;
    margin-right: 24px;
    top: 0;
    margin-top: 0;
    z-index: 1070;
}

.menu-group--primary {
    color: #aad8f1;
    padding-top: 0;
}

.menu-group {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.125rem;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0 0 0.75rem;
}
.menu-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.menu-group--primary>.menu-item>.menu-item__title {
    font-size: 1.5rem;
    padding-bottom: 1rem;
    padding-top: 0.375rem;
}

.menu-item__title {
    background-color: transparent;
    border: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: currentColor;
    cursor: pointer;
    display: block;
    font-size: 30px;
    outline: none;
    padding: 0.5rem 2.3125rem 0.5rem 3.125rem;
    position: relative;
    text-align: left;
    text-transform: lowercase;
    width: 100%;
}

  .new-header--open .menu {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      margin-top: 0;
  }
  .menu {
      opacity: 0;
      -webkit-transform: translateX(-110%);
      transform: translateX(-110%);
      -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      -webkit-overflow-scrolling: touch;
      -webkit-box-shadow: 3px 0 1rem rgba(0,0,0,0.4);
      box-shadow: 3px 0 1rem rgba(0,0,0,0.4);
      bottom: 0;
      height: 100%;
      overflow: auto;
      padding-top: 0.375rem;
      position: fixed;
      right: 0;
      -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
      transition: -webkit-transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
      transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
      will-change: transform;
  }
  .new-header--open .menu {
    opacity: 1;
  }
  .navbar-nav {
    margin-left: 0;
    li {
      float: none !important;
    }
  }

  #header {
    .menu {
      li {
        margin: 0 !important;
        overflow: hidden;
        a {
          padding: 10px 0 !important;
          margin: 5px 10px 0px 5%;
          color: #ffffff !important;
        }
        a:hover, a:focus {
          background-color: transparent;
          border-bottom: none !important;
        }
      }
      li.active {
        a {
          background-color: transparent;
          border-bottom: none !important;          
        }
      }
    }
    .dropdown-menu {
      display: block !important;
      position: relative;
      width: 100%;
      box-shadow: none !important;
      top: auto !important;
      background-color: #00afec;
      height: auto;
      text-align: left;
      li {
        position: relative;
        display: block;
        a {
          padding: 10px 0 !important;
          display: block;
          color: #ffffff !important; 
        }
      }
    }
  }

  .menu {
      background-color: #00afec;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      color: #ffffff;
      font-family: "Guardian Text Sans Web","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
      font-size: 30px;
      left: 0;
      line-height: 1;
      margin-right: 0;
      padding-bottom: 1.5rem;
      top: 0;
      z-index: 1070;
  }

  .search-block-form {
      width: 200px;
      float: right;
      position: fixed;
      top: 30px;
      right: 100px;
  }
}

@media (max-width: 767px) {

  #block-views-block-people-people .view-content .inner {
    padding-bottom: 0;
  }
  #block-bootstrap-ensphere-search {
    display: none;
  }
  .logo-container {
    max-width: 140px !important;
  }
  .banner-section, .banner-section .banner {
    height: 300px;
    min-height: 300px;
  }
  .view-banner-section .content-overlay {
    height: 300px;
  }
  .padding-lrg {
    padding: 40px 0;
  }

  .layout--twocol > .layout__region {
    padding-left: 15px;
    padding-right: 15px;
  }

  .image-item {
    width: 100%;
  }
  .view-project-filter .view-content {
    top: -64px;
  }
  .project-type {
  }

  .full_page_video #video-background {
    display: none;
  }

}

    .video-bg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      z-index: 3;
      background-color: #000000;
    }

@media (min-width: 768px) {
  #block-views-block-people-people .view-content .people .people-img {
    height: 240px;
  }
  #block-views-block-projects-block-1 .view-content .project .project-img, 
  #block-views-block-projects-related-projects .view-content .project .project-img {
    height: 200px;
  }

  #block-views-block-people-people, #block-views-block-projects-related-projects {
    padding: 50px 0;
  }

  #block-views-block-projects-block-1 {
    padding: 0;
  }


  #block-views-block-services-block-1 {
    padding: 0 0 50px 0;
  }

  #block-views-block-services-related-services {
    padding: 0 0 50px 0;
  }

  #block-views-block-services-related-services h2 {
    margin-bottom: 30px;
  }
  .veggie-burger {
    top: 23px;
  }

}

@media (min-width: 992px) {

  .veggie-burger, #main-menu {
    display: none;
  }
  h1 {
    font-size: 32px;
    line-height: 135%;
    margin: 0 0 10px 0;
    padding: 0;
  }
  .layout--twocol > .layout__region--first{
    width: 60%;
    padding-left: 15px;
    padding-right: 15px;
    .field--name-field-quote {
      margin-top: 150px;
    }
  } 
  .layout--twocol > .layout__region--second {
    width: 40%;
    padding-left: 40px;
    padding-top: 100px;
    padding-right: 15px;
    img {
      width: 100%;
    }    
  }
 .project .layout--twocol > .layout__region--second {
    padding-top: 350px;
  }
  .project-type {
    min-width: 31%;
  }

  .field--name-field-quote:before {
    content: "\f10d";
    font-family: FontAwesome;
    font-size: 40px;
    line-height: 100%;
    display: block;
  }
  .field--name-field-quote {
    color: #35bfee;    
    font-size: 26px;
    line-height: 135%;
  }

  .view-banner-section .content-overlay {
    padding: 0 10%;
    .banner-title {
      font-size: 42px;
      line-height: 135%;
      margin-bottom: 10px;
    }
    .banner-text {
      font-size: 20px;
      line-height: 135%;
    }
    .btn-down {
      bottom: 40px;
    }
  }

  #video-wrapper {
    position: relative;
    overflow:hidden;
    height: 612px;
    min-height: 600px;
    padding: 170px 0 79px;
    text-align: center;
    .video-bg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      z-index: 3;
      background-color: #000000;
    }
  }

  #video {
    .content-overlay {
      padding: 0;
        .field--name-field-banner-title {
          font-size: 42px;
          line-height: 135%;
          margin-bottom: 10px;
          -webkit-animation-delay: 5s;
          -moz-animation-delay: 5s;
          animation-delay: 5s;
          -webkit-animation: fadein ease-in 1; /* Safari, Chrome and Opera > 12.1 */
             -moz-animation: fadein ease-in 1; /* Firefox < 16 */
              -ms-animation: fadein ease-in 1; /* Internet Explorer */
               -o-animation: fadein ease-in 1; /* Opera < 12.1 */
                  animation: fadein ease-in 1;
          -webkit-animation-duration: 4s;
          -moz-animation-duration: 4s;
          animation-duration: 4s; 
        }
        .field--name-field-banner-text {
          font-size: 20px;
          line-height: 135%;
        }      
    }
  }

  .break-image {
    background-attachment: fixed;
  }

  .padding-lrg {
    padding: 70px 0;
  }
  #header .menu.navbar-nav li a {
    font-size: 17px;
  }

  #block-bootstrap-ensphere-main-menu {
    margin-top: 14px;
  }
  .caret { display: none; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media (min-width: 1200px) {
  #block-views-block-people-people .view-content .people .people-img {
    height: 270px;
  }
  #block-views-block-projects-block-1 .view-content .project .project-img, 
  #block-views-block-projects-related-projects .view-content .project .project-img {
    height: 240px;
  }
  #header .menu.navbar-nav li a {
    font-size: 19px;
  }

  .field--name-field-service-people {
  .field--item {
    width: 50%;
  }
}

}


