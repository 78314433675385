/**
 * Node styling.
 */

.node-preview-container {
  margin-top: -($grid-gutter-width / 2);
}

.node-preview-form-select {
  padding: ($grid-gutter-width / 2);
}
