/**
 * Alert styling.
 */
.alert-sm {
  padding: 5px 10px;
}

// Treat all links inside alert as .alert-link
.alert a {
  font-weight: $alert-link-font-weight;
}
.alert-success {
  a, a:hover, a:focus {
    color: darken($alert-success-text, 10%);
  }
}
.alert-info {
  a, a:hover, a:focus {
    color: darken($alert-info-text, 10%);
  }
}
.alert-warning {
  a, a:hover, a:focus {
    color: darken($alert-warning-text, 10%);
  }
}
.alert-danger {
  a, a:hover, a:focus {
    color: darken($alert-danger-text, 10%);
  }
}
