/**
 * File (and Image Widget) styles.
 */
.file {
  display: table;
  font-size: 75%;
  font-weight: 700;
  margin: 5px 0;
  width: 100%;
  > span {
    background: #fff;
    color: $brand-primary;
    border-bottom: 1px solid $input-border;
    border-top: 1px solid $input-border;
    &:first-child {
      border-left: 1px solid $input-border;
    }
    &:last-child {
      border-right: 1px solid $input-border;
    }
  }
  > .tabledrag-changed {
    &, &:last-child {
      border: 1px solid darken($alert-warning-border, 5%);
    }
    background: $alert-warning-bg;
    border-radius: 0;
    color: $alert-warning-text;
    display: table-cell;
    padding: 0 1em;
    top: 0;
    vertical-align: middle;
    border-left: 1px solid inherit;
  }
}
.file-icon {
  display: table-cell;
  font-size: 150%;
  padding: .25em .5em;
  text-align: center;
  vertical-align: middle;
}
.file-link {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  a, a:hover, a:focus, a:active {
    color: inherit;
  }
}
.file-size {
  display: table-cell;
  padding: 0 1em;
  text-align: right;
  white-space: pre;
  vertical-align: middle;
}

.image-widget.row {
  overflow: hidden;
}
