/**
 * Icon styles.
 */
a {
  &.icon-before .glyphicon {
    margin-right: .25em;
  }
  &.icon-after .glyphicon {
    margin-left: .25em;
  }
}

.btn {
  &.icon-before .glyphicon {
    margin-left: -.25em;
    margin-right: .25em;
  }
  &.icon-after .glyphicon {
    margin-left: .25em;
    margin-right: -.25em;
  }
}
