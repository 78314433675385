/**
 * Table drag styles.
 */
.tabledrag-toggle-weight {
  float: right;
  margin: 1px 2px 1px 10px;
}
.tabledrag-changed-warning {
  margin: 0;
  overflow: hidden;
}
.tabledrag-handle {
  color: $gray-light;
  cursor: move;
  float: left;
  font-size: 125%;
  line-height: 1;
  margin: -10px 0 0 -10px;
  padding: 10px;
  &:hover, &:focus {
    color: $brand-primary;
  }
}
.indentation {
  float: left; /* LTR */
  height: 1.7em;
  margin: -0.4em 0.2em -0.4em -0.4em; /* LTR */
  padding: 0.42em 0 0.42em 0.6em; /* LTR */
  width: 20px;
  [dir="rtl"] & {
    float: right;
    margin: -0.4em -0.4em -0.4em 0.2em;
    padding: 0.42em 0.6em 0.42em 0;
  }
}
