/**
 * Navbar styling.
 */
$mobile:          "screen and (max-width: #{$screen-xs-max})";
$tablet:          "screen and (min-width: #{$screen-sm-min})";
$normal:          "screen and (min-width: #{$screen-md-min})";
$wide:            "screen and (min-width: #{$screen-lg-min})";
$grid-breakpoint: "screen and (min-width: #{$grid-float-breakpoint})";

body {
  // Fix horizontal scrolling on iOS devices.
  // http://drupal.org/node/1870076
  position: relative;

  &.navbar-is-static-top {
    margin-top: 0;
  }
  &.navbar-is-fixed-top {
    margin-top: ($navbar-height + ($grid-gutter-width / 2));
  }
  &.navbar-is-fixed-bottom {
    padding-bottom: ($navbar-height + ($grid-gutter-width / 2));
  }

  @media #{$tablet} {
    margin-top: ($grid-gutter-width / 2);
  }
  @media #{$mobile} {
    &.toolbar-vertical {
      &.navbar-is-fixed-top,
      &.navbar-is-fixed-bottom {
        .toolbar-bar {
          position: fixed;
        }
        header {
          z-index: 500;
        }
      }
      // Default toolbar fixed height value.
      // @see core/modules/toolbar/css/toolbar.icons.theme.css@261
      &.navbar-is-fixed-top header {
        top: 39px;
      }
    }
  }
}
.navbar.container {
  @media #{$tablet} {
    max-width: (($container-sm - $grid-gutter-width));
  }
  @media #{$normal} {
    max-width: (($container-md - $grid-gutter-width));
  }
  @media #{$wide} {
    max-width: (($container-lg - $grid-gutter-width));
  }
}

// Branding.
.navbar .logo {
  margin-right: -($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @media #{$grid-breakpoint} {
    margin-right: 0;
    padding-left: 0;
  }
}

