/**
 * Filter styles.
 */

// Mimic .panel-default styling.
.filter-wrapper {
  background-color: $panel-bg;
  border: 1px solid $panel-default-border;
  border-top: 0;
  border-radius: 0 0 $panel-border-radius $panel-border-radius;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  margin-bottom: 0;
  padding: 10px;
  height: 51px;
  @extend .clearfix;
}
.filter-help {
  float: right;
  line-height: 1;
  margin: .5em 0 0;
}

// Full list page.
.nav.nav-tabs.filter-formats {
  margin-bottom: 15px;
}
